import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import cattos from './cats';
import Cat from './Cat';
import Title from './Title';

const CattoList = () => {
  return (
    <section className='catlist'>
      {cattos.map((catto, index) => {
        return <Cat {...catto} key={catto.id} index={index} />;
      })}
    </section>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Title>Top 5 Cat Emotions</Title>
    <CattoList />
  </>
);
