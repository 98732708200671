const Cat = (props) => {
  const { img, title, appearance, index } = props;
  return (
    <article className='cat'>
      <img src={img} alt={title} />
      <h2>{title}</h2>
      <h4>{appearance}</h4>
      <span className='number'>#{index + 1}</span>
    </article>
  );
};

export default Cat;
