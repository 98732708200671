import img1 from './images/grumpy.jpg';
import img2 from './images/polite.jpg';
import img3 from './images/crying.jpg';
import img4 from './images/coughing.png';
import img5 from './images/demon.jpg';
//Importing in such a way optimises the app.
const cattos = [
  {
    title: 'Grumpy Cat',
    appearance: 'Not Amused',
    img: img1,
    id: 0,
  },
  {
    title: 'Polite Cat',
    appearance: 'Awkward Smile',
    img: img2,
    id: 1,
  },
  {
    title: 'Sad Cat',
    appearance: 'Unwavering Despair',
    img: img3,
    id: 2,
  },
  {
    title: 'Coughing Cat',
    appearance: 'Plagued By Illness',
    img: img4,
    id: 3,
  },
  {
    title: 'Angry Cat',
    appearance: 'Demon Spawn',
    img: img5,
    id: 4,
  },
];

export default cattos;
